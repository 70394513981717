import React from 'react';
import { Route, Switch } from "react-router-dom";
import Loadable from 'react-loadable';
import PrivateRoute from '../../components/PrivateRoute';
import { useErpContext } from '../../contexts/erpContext';
import { CONFIG } from '../../config';
import { userHasRole } from '../../util/user';

const loading = () => <div/>;

const BuyVignette = Loadable({
  loader: () => import(/* webpackChunkName: "BuyVignette" */ '../../components/BuyVignette'),
  loading,
  modules: ['BuyVignette'],
});
const Login = Loadable({
  loader: () => import(/* webpackChunkName: "Login" */ '../Login'),
  loading,
  modules: ['Login'],
});
const UserManagement = Loadable({
  loader: () => import(/* webpackChunkName: "UserManagement" */ '../UserManagement'),
  loading,
  modules: ['UserManagement'],
});
const PartnerManagement = Loadable({
  loader: () => import(/* webpackChunkName: "PartnerManagement" */ '../PartnerManagement'),
  loading,
  modules: ['PartnerManagement'],
});
const PartnerManagementNotoll = Loadable({
  loader: () => import(/* webpackChunkName: "SalesPartnerManagement" */ '../PartnerManagementNotoll'),
  loading,
  modules: ['SalesPartnerManagement'],
});
const SalesPartnerManagement = Loadable({
  loader: () => import(/* webpackChunkName: "SalesPartnerManagement" */ '../SalesPartnerManagement'),
  loading,
  modules: ['SalesPartnerManagement'],
});
const PosManagement = Loadable({
  loader: () => import(/* webpackChunkName: "PosManagement" */ '../PosManagement'),
  loading,
  modules: ['PosManagement'],
});
const Inquiry = Loadable({
  loader: () => import(/* webpackChunkName: "Inquiry" */ '../Inquiry'),
  loading,
  modules: ['Inquiry'],
});
const Report = Loadable({
  loader: () => import(/* webpackChunkName: "Report" */ '../Report'),
  loading,
  modules: ['Report'],
});
const TrialBalance = Loadable({
  loader: () => import(/* webpackChunkName: "TrialBalance" */ '../TrialBalance'),
  loading,
  modules: ['TrialBalance'],
});
const ContractManagement = Loadable({
  loader: () => import(/* webpackChunkName: "ContractManagement" */ '../ContractManagement'),
  loading,
  modules: ['PartnerManagement'],
});
const VehicleManagement = Loadable({
  loader: () => import(/* webpackChunkName: "VehicleManagement" */ '../VehicleManagement'),
  loading,
  modules: ['VehicleManagement'],
});
const ServiceFees = Loadable({
  loader: () => import(/* webpackChunkName: "ServiceFees" */ '../ServiceFees'),
  loading,
  modules: ['ServiceFees'],
});
const ObuManagement = Loadable({
  loader: () => import(/* webpackChunkName: "ObuManagement" */ '../ObuManagement'),
  loading,
  modules: ['ObuManagement'],
});
const ObuKapschDeliveries = Loadable({
  loader: () => import(/* webpackChunkName: "ObuKapschDeliveries" */ '../ObuKapschDeliveries'),
  loading,
  modules: ['ObuManagement'],
});
const ObuDeliveries = Loadable({
  loader: () => import(/* webpackChunkName: "ObuDeliveries" */ '../ObuDeliveries'),
  loading,
  modules: ['ObuManagement'],
});
const WarehouseManagement = Loadable({
  loader: () => import(/* webpackChunkName: "WarehouseManagement" */ '../WarehouseManagement'),
  loading,
  modules: ['ObuManagement'],
});
const BankReport = Loadable({
  loader: () => import(/* webpackChunkName: "BankReport" */ '../BankReport'),
  loading,
  modules: ['BankReport'],
});
const BankReportFailed = Loadable({
  loader: () => import(/* webpackChunkName: "BankReportFailed" */ '../BankReportFailed'),
  loading,
  modules: ['BankReport'],
});
const Notifications = Loadable({
  loader: () => import(/* webpackChunkName: "Notifications" */ '../Notifications'),
  loading,
  modules: ['Notifications'],
});
const RoutePass = Loadable({
  loader: () => import(/* webpackChunkName: "RoutePass" */ '../../components/RoutePass'),
  loading,
  modules: ['RoutePass'],
});
const Cart = Loadable({
  loader: () => import(/* webpackChunkName: "Cart" */ '../Cart'),
  loading,
  modules: ['Cart'],
});
const Announcements = Loadable({
  loader: () => import(/* webpackChunkName: "Announcements" */ '../Announcements'),
  loading,
  modules: ['Announcements']
});
const ShiftsManagement = Loadable({
  loader: () => import(/* webpackChunkName: "ShiftsManagement" */ '../ShiftsManagement'),
  loading,
  modules: ['ShiftsManagement'],
});
const SearchDelictPayments = Loadable({
  loader: () => import(/* webpackChunkName: "SearchDelictPayments" */ '../DelictPayments/SearchDelictPayments'),
  loading,
  modules: ['SearchDelictPayments']
});
const MassMailing = Loadable({
  loader: () => import(/* webpackChunkName: "VehicleManagement" */ '../MassMailing'),
  loading,
  modules: ['MassMailing'],
});
const Invoices = Loadable({
  loader: () => import(/* webpackChunkName: "Invoices" */ '../Invoices'),
  loading,
  modules: ['Invoices'],
});
const Delicts = Loadable({
  loader: () => import(/* webpackChunkName: "Delicts" */ '../Delicts'),
  loading,
  modules: ['Delicts'],
});
const OngoingPayments = Loadable({
  loader: () => import(/* webpackChunkName: "OngoingPayments" */ '../OngoingPaymentsReport'),
  loading,
  modules: ['OngoingPayments'],
});

const AppRouter = function({ match }) {
  const {userAllowed, userIs, roleList, isUserDelict} = useErpContext();

  const getHomeComponent = () => {
    if (CONFIG.ENABLE_TOLLING) {
      if (userIs('ROLE_KAPSCH_OBU_SERVICE') || userIs('ROLE_TOLL_ADMIN')) {
        return ObuManagement
      } else if (userAllowed('ROLE_ADMIN')) {
        return ContractManagement
      }
      return Login
    }
    return Inquiry;
  };

  return (
    <Switch>
      <Route path={`${match.url}/login`} component={Login}/>
      {!roleList(['ROLE_ACCOUNTANT', 'ROLE_KAPSCH_OBU_SERVICE', 'ROLE_TOLL_ADMIN', 'ROLE_RIA']) && CONFIG.ENABLE_VIGNETTE && <PrivateRoute path={`${match.url}/buy-vignette`} component={BuyVignette} />}
      {!roleList(['ROLE_ACCOUNTANT', 'ROLE_KAPSCH_OBU_SERVICE', 'ROLE_TOLL_ADMIN', 'ROLE_RIA']) && CONFIG.ENABLE_ROUTE_PASS && <PrivateRoute path={`${match.url}/buy-route-pass`} component={RoutePass} />}
      {!roleList(['ROLE_ACCOUNTANT', 'ROLE_KAPSCH_OBU_SERVICE', 'ROLE_TOLL_ADMIN', 'ROLE_RIA']) && (CONFIG.ENABLE_ROUTE_PASS || CONFIG.ENABLE_VIGNETTE) && <PrivateRoute path={`${match.url}/shopping-cart`} component={Cart} />}
      {(!userIs('ROLE_TOLL_ADMIN') && !userIs('ROLE_KAPSCH_OBU_SERVICE')) && (CONFIG.ENABLE_ROUTE_PASS || CONFIG.ENABLE_VIGNETTE) && <PrivateRoute path={`${match.url}/inquiry`} component={Inquiry} />}

      {userAllowed('ROLE_ADMIN') && <PrivateRoute path={`${match.url}/sales-partner-management`} component={CONFIG.ENABLE_TOLLING ? SalesPartnerManagement : PartnerManagementNotoll} />}
      {(userAllowed('ROLE_PARTNER_ADMIN') && !userIs('ROLE_TOLL_ADMIN')) && <PrivateRoute path={`${match.url}/pos-management`} component={PosManagement} />}
      {((userAllowed('ROLE_PARTNER_ADMIN') && !userIs('ROLE_TOLL_ADMIN'))) && <PrivateRoute path={`${match.url}/user-management`} component={UserManagement} />}
      {(!userIs('ROLE_TOLL_ADMIN') && !userIs('ROLE_KAPSCH_OBU_SERVICE') && userAllowed('ROLE_ACCOUNTANT')) && <PrivateRoute path={`${match.url}/report`} component={Report} />}
      
      {CONFIG.ENABLE_TOLLING && (userIs('ROLE_ACCOUNTANT') || userAllowed('ROLE_ADMIN')) && <PrivateRoute path={`${match.url}/trial-balance`} component={TrialBalance} />}
      {CONFIG.ENABLE_TOLLING && (userIs('ROLE_ACCOUNTANT') || userIs('ROLE_ADMIN')) && <PrivateRoute path={`${match.url}/ongoing-payments`} component={OngoingPayments} />}
      {CONFIG.ENABLE_TOLLING && (userIs('ROLE_ACCOUNTANT') || userAllowed('ROLE_ADMIN') || userHasRole('ROLE_CUSTOMERS_READ') || userHasRole('ROLE_CUSTOMERS_WRITE')) && <PrivateRoute path={`${match.url}/partner-management`} component={PartnerManagement} />}
      {CONFIG.ENABLE_TOLLING && (userIs('ROLE_ACCOUNTANT') || userAllowed('ROLE_ADMIN')) && <PrivateRoute path={`${match.url}/contract-management/:partnerId`} component={ContractManagement} />}
      {CONFIG.ENABLE_TOLLING && (userIs('ROLE_ACCOUNTANT') || userAllowed('ROLE_ADMIN') || userHasRole('ROLE_CUSTOMERS_READ') || userHasRole('ROLE_CUSTOMERS_WRITE')) && <PrivateRoute path={`${match.url}/contract-management/`} component={ContractManagement} />}
      {CONFIG.ENABLE_TOLLING && (userIs('ROLE_ACCOUNTANT') || userAllowed('ROLE_ADMIN')) && <PrivateRoute path={`${match.url}/vehicle-management/:contractId`} component={VehicleManagement} />}
      {CONFIG.ENABLE_TOLLING && (userIs('ROLE_ACCOUNTANT') || userAllowed('ROLE_ADMIN') || userHasRole('ROLE_UNITS_READ') || userHasRole('ROLE_UNITS_WRITE')) && <PrivateRoute path={`${match.url}/vehicle-management`} component={VehicleManagement} />}
      {CONFIG.ENABLE_TOLLING && (userHasRole('ROLE_ACCOUNTANT') || userHasRole('ROLE_ADMIN')) && <PrivateRoute path={`${match.url}/service-fees`} component={ServiceFees} />}
      {CONFIG.ENABLE_TOLLING && (userIs('ROLE_KAPSCH_OBU_SERVICE') || userAllowed('ROLE_PARTNER_ADMIN') || userHasRole('ROLE_OBU_READ') || userHasRole('ROLE_OBU_WRITE')) && <PrivateRoute path={`${match.url}/obu-management`} component={ObuManagement} />}
      {CONFIG.ENABLE_TOLLING && (((userIs('ROLE_KAPSCH_OBU_SERVICE') || userAllowed('ROLE_PARTNER_ADMIN')) && !userIs('ROLE_TOLL_ADMIN')) || userHasRole('ROLE_OBU_READ') || userHasRole('ROLE_OBU_WRITE'))&& <PrivateRoute path={`${match.url}/obu-kapsch-deliveries`} component={ObuKapschDeliveries} />}
      {CONFIG.ENABLE_TOLLING && (userIs('ROLE_KAPSCH_OBU_SERVICE') || userAllowed('ROLE_PARTNER_ADMIN') || userHasRole('ROLE_OBU_READ') || userHasRole('ROLE_OBU_WRITE')) && <PrivateRoute path={`${match.url}/obu-deliveries`} component={ObuDeliveries} />}
      {CONFIG.ENABLE_TOLLING && (userIs('ROLE_KAPSCH_OBU_SERVICE') || userAllowed('ROLE_PARTNER_ADMIN') || userHasRole('ROLE_OBU_READ') || userHasRole('ROLE_OBU_WRITE')) && <PrivateRoute path={`${match.url}/warehouse`} component={WarehouseManagement} />}
      {CONFIG.ENABLE_TOLLING && roleList(['ROLE_ACCOUNTANT', 'ROLE_ADMIN', 'ROLE_CONCORD_ADMIN']) && <PrivateRoute path={`${match.url}/bank-report`} component={BankReport} />}
      {CONFIG.ENABLE_TOLLING && roleList(['ROLE_ACCOUNTANT', 'ROLE_ADMIN', 'ROLE_CONCORD_ADMIN']) && <PrivateRoute path={`${match.url}/bank-report-failed`} component={BankReportFailed} />}
      {(CONFIG.ENABLE_TOLLING && userAllowed('ROLE_ADMIN')  || userHasRole('ROLE_NOTIFICATIONS_READ')) && <PrivateRoute path={`${match.url}/notifications`} component={Notifications} />}
      {CONFIG.ENABLE_TOLLING && (userHasRole('ROLE_ADMIN') || userHasRole('ROLE_CONCORD_ADMIN')) && <PrivateRoute path={`${match.url}/mass-mailing`} component={MassMailing} />}
      {CONFIG.ENABLE_TOLLING && (userHasRole('ROLE_ACCOUNTANT') || userHasRole('ROLE_ADMIN') || userHasRole('ROLE_CONCORD_ADMIN') || userHasRole('ROLE_VIEW_INVOICES')) && <PrivateRoute path={`${match.url}/invoices`} component={Invoices} />}
      {CONFIG.ENABLE_TOLLING && (userHasRole('ROLE_ADMIN') || userHasRole('ROLE_CONCORD_ADMIN')) && <PrivateRoute path={`${match.url}/delicts`} component={Delicts} />}
      {!CONFIG.ENABLE_TOLLING && (userIs('ROLE_ADMIN') || isUserDelict()) && <PrivateRoute path={`${match.url}/search-delict-payments`} component={SearchDelictPayments} />}
      {!CONFIG.ENABLE_TOLLING && userIs('ROLE_ADMIN') && <PrivateRoute path={`${match.url}/announcements`} component={Announcements} />}
      {!CONFIG.ENABLE_TOLLING && userAllowed('ROLE_PARTNER_ADMIN') && <PrivateRoute path={`${match.url}/shifts`} component={ShiftsManagement} />}

      <PrivateRoute path={`${match.url}`} component={getHomeComponent()} />
    </Switch>
  );
};

export default AppRouter;