import {CONFIG} from '../config';

export const LANGUAGES = [
    {
        lang: 'en',
        text: 'English',
        icon: 'flag-en'
    },
    {
        lang: 'bg',
        text: 'Български',
        icon: 'flag-bg'
    },
];

export const FEATURES_KEY = "0fb2a5aa93163910db403fd8a6d2fc28";
export const BASE_URL = CONFIG.BACKEND_URL;
export const BACKEND_LOC_URL = CONFIG.LOCAL_WINDOWS_SERVICE_URL;

export const ERROR_MAP = {
    201: 'е-Винетката не съществува',
    202: 'Не можете да закупите е-Винетка от този тип за тази дата',
    203: 'Невалиден имейл',
    204: 'Невалидни регинстрационен номер или държава',
    206: 'Посочената начална дата е в миналото',
    207: 'Посочената начална дата е твърде далеч в бъдещето',
    208: 'Не е намерена такава е-Винетка',
    210: 'е-Винетката е вече активирана',
    217: 'Вече съществува закупена винетка за този номер и валидност',
};

export const minLengthPassword = 8;
export const maxLengthPassword = 250;
export const minLengthUsername = 4;
export const maxLengthUsername = 100;
export const minLengthLPN = 1;
export const maxLengthLPN = 10;
export const fixedExchangeRateOfEuro= 1.9558300;

export const passwordRequirements = 'Паролата трябва да съдържа главна латинска буква, малка латинска буква и цифра';
export const emailRequirements = 'APP.COMMON.ERROR.INVALID_MAIL';
export const phoneRequirements = 'Телефонният номер може да съдържа само цифри';
export const passwordMatch = 'Паролите трябва да съвпадат';
export const COUNTRY_CODE_BG = 'BG'

export const routePassColors = {
    FREE: '#329600',
    FIRST_CLASS: '#ff00ff',
    SECOND_CLASS: '#a525c2',
    HIGHWAY: 'red'
};

export const obuStatuses = {
    1: 'NEW',
    2: 'REFURBISHED',
    3: 'REPLACED',
    4: 'RECYCLED',
    // 20: 'OBSOLETE'
};

export const obuListStatusesFilter = [
    { id: 'NEW', name: 'NEW' },
    { id: 'REFURBISHED', name: 'REFURBISHED' },
    { id: 'REPLACED', name: 'REPLACED' },
    { id: 'RECYCLED', name: 'RECYCLED' }
];

export const closeAccountReasons = {
    bg: [
        { id: 1, description: 'Заболяване на клиента' },
        { id: 2, description: 'Преместване на клиента' },
        { id: 3, description: 'Анулирано разрешение на превозното средство' },
        { id: 4, description: 'Друго' }
    ],
    en: [
        { id: 1, description: 'Customer disease' },
        { id: 2, description: 'Customer relocation' },
        { id: 3, description: 'Vehicle license revoke' },
        { id: 4, description: 'Other' }
    ]
};

export const obuDeliveryTypes = {
    bg: [
        { id: 'POS_SUPPLY', name: 'Снабдяване' },
        { id: 'OBUS_RETURN', name: 'Връщане' },
        { id: 'RECYCLING', name: 'Рециклиране' },
        { id: 'SERVICED_OBUS_BACK', name: 'Сервизирани ОБУ' },
        { id: 'KAPSCH_VIENNA', name: 'Капш Виена' }
    ],
    en: [
        { id: 'POS_SUPPLY', name: 'Supply' },
        { id: 'OBUS_RETURN', name: 'Return' },
        { id: 'RECYCLING', name: 'Recycling' },
        { id: 'SERVICED_OBUS_BACK', name: 'Serviced OBUs' },
        { id: 'KAPSCH_VIENNA', name: 'Kapsch Vienna' }
    ]
};

export const obuDeliveryTypesFilter = [
    { id: 'POS_SUPPLY', name: 'POS_SUPPLY' },
    { id: 'OBUS_RETURN', name: 'OBUS_RETURN' },
    { id: 'RECYCLING', name: 'RECYCLING' },
    { id: 'SERVICED_OBUS_BACK', name: 'SERVICED_OBUS_BACK' },
    { id: 'KAPSCH_VIENNA', name: 'KAPSCH_VIENNA' }
];

export const fileNamesByobuDeliveryTypes = [
    { id: 'POS_SUPPLY', name: 'pos-supply' },
    { id: 'OBUS_RETURN', name: 'return' },
    { id: 'RECYCLING', name: 'recycling' },
    { id: 'SERVICED_OBUS_BACK', name: 'serviced-back' },
    { id: 'KAPSCH_VIENNA', name: 'kapsh-vienna' }
];

export const obuStatusesFilter = [
    { id: 'REQUESTED', name: 'REQUESTED' },
    { id: 'SHIPPED', name: 'SHIPPED' },
    { id: 'DELIVERED', name: 'DELIVERED' },
    { id: 'CREATED', name: 'CREATED' },
    { id: 'LOST', name: 'LOST' },
    { id: 'STOLEN', name: 'STOLEN' }
];

export const obuServiceCheckStatus = [
    { id: 1, name: 'OK' },
    { id: 2, name: 'SCRAP' },
    { id: 3, name: 'DAMAGED' },
    { id: 4, name: 'DEFECTIVE' },
    { id: 5, name: 'NOT_CHECKED' },
    { id: 6, name: 'DEFECTIVE_REPLACED' }
];

export const obuDeviceTypeFilter = [
    { id: 'OBU', name: 'OBU' },
    { id: 'TRACKER', name: 'TRACKER' }
];

export const obusInReturn = ['RECYCLING', 'SERVICED_OBUS_BACK', 'KAPSCH_VIENNA', 'OBUS_RETURN'];

export const manualDeliveryStatuses = ['REQUESTED', 'SHIPPED'];

export const adjustmentsCreditDebitFilter = [
    { id: 'TOLL_ADJUSTMENT_DEBIT', name: 'Debit' },
    { id: 'TOLL_ADJUSTMENT_CREDIT', name: 'Credit' }
];

export const obuLocationStatusesFilter = [
    { id: 'AVAILABLE', name: 'AVAILABLE' },
    { id: 'ATTACHED', name: 'ATTACHED' },
    { id: 'DETACHED', name: 'DETACHED' },
    { id: 'IN_TRANSIT', name: 'IN_TRANSIT' },
    { id: 'FOR_RECYCLING', name: 'FOR_RECYCLING' },
    { id: 'DISCARDED', name: 'DISCARDED' }
];

export const activeTypes = [
    { id: 'ACTIVE', name: 'APP.COMMON.COMMON.ACTIVE' },
    { id: 'INACTIVE', name: 'НеAPP.COMMON.COMMON.ACTIVE' }
];

export const yesNoOptions = [
    { id: true, name: 'да' },
    { id: false, name: 'не' }
];

export const yesNoOptionsEN = [
    { id: true, name: 'yes' },
    { id: false, name: 'no' }
];

export const notificationType = [
    { id: 'C5B_NOTIFICATION', name: 'C5B' },
    { id: 'C10A_NOTIFICATION', name: 'C10A' }
];

export const accountStatuses = [
    { id: 'Active', name: 'Active' },
    { id: 'Pre-Active', name: 'Pre-Active' },
    { id: 'Pre-Closed', name: 'Pre-Closed' },
    { id: 'Suspended', name: 'Suspended' },
    { id: 'Closed', name: 'Closed' }
];

export const unitStatuses = [
    { id: 'Active', name: 'Active' },
    { id: 'Pre-Active', name: 'Pre-Active' },
    { id: 'Pre-Closed', name: 'Pre-Closed' },
    { id: 'Inactive', name: 'Inactive' },
    { id: 'Closed', name: 'Closed' }
];

export const contractTypes = [
    { id: 1, name: 'APP.COMMON.COMMON.PREPAID' },
    { id: 2, name: 'APP.COMMON.COMMON.POSTPAID' }
];

export const contractTypesEN = [
    { id: 1, name: 'Prepaid Account' },
    { id: 2, name: 'Postpaid Account' }
];

export const partnerTypes = [
    { id: 'SALE_PARTNER', name: 'Търговски партньор' },
    { id: 'COMMISSIONER', name: 'Комисионер' }
];

export const partnerTypesEN = [
    { id: 'SALE_PARTNER', name: 'Sale partner' },
    { id: 'COMMISSIONER', name: 'Commissioner' }  
];

export const vehicleTypes = {
    HGV_UP_TO_12T: 'Товарен автомобил с ОТДММ над 3.5т и до 12т',
    LARGE_HGV_MORE_THAN_12T: 'Товарен автомобил с ОТДММ 12т и повече',
    SMALL_PASSENGER_VEHICLE: 'Пътнически превозни средства с над 8 места плюс водача с ОТДММ над 3.5т и до 12т',
    LARGE_PASSENGER_VEHICLE: 'Пътнически превозни средства с над 8 места плюс водача с ОТДММ 12т и повече'
};

export const shiftStatuses = {
    bg: [
        { id: 1, name: 'отворена' },
        { id: 2, name: 'затворена' }
    ],
    en: [
        { id: 1, name: 'open' },
        { id: 2, name: 'close' }
    ]
};

export const productTypes = {
    compensatoryFee: "COMPENSATORY_FEE",
    routePass: "ROUTE_PASS",
    eVignette: "E_VIGNETTE"
};

export const mockDelicts = {
    "elements": [
      {
        "amountBGN": 0,
        "amountEUR": 0,
        "delictDocumentNumber": "string",
        "delictID": "string",
        "delictValidityPeriod": "2021-06-16T06:23:56.493Z",
        "documentStatusTypeName": "string",
        "documentTypeCategoryName": "string",
          "closed":true,
        "evidentialRecords": [
          {
            "coordinate1": "string",
            "coordinate2": "string",
            "direction": "string",
            "district": "string",
            "km": "string",
            "location": "string",
            "municipality": "string",
            "passageDate": "2021-06-16T06:23:56.493Z",
            "passageDateUtc": "2021-06-16T06:23:56.493Z",
            "road": "string",
            "tollSectionId": 0,
            "tollSectionName": "string",
            "typeBG": "string",
            "typeEN": "string"
          }
        ],
        "hasExpired": true,
        "licensePlate": "string",
        "nationality": "string",
        "owners": [
          {
            "vehicleOwnerId": "string"
          }
        ],
        "paymentDocumentNumber": "string"
      },
      {
        "amountBGN": 0,
        "amountEUR": 0,
        "delictDocumentNumber": "string",
        "delictID": "string",
        "delictValidityPeriod": "2021-06-16T06:23:56.493Z",
        "documentStatusTypeName": "string",
        "documentTypeCategoryName": "string",
        "evidentialRecords": [
          {
            "coordinate1": "string",
            "coordinate2": "string",
            "direction": "string",
            "district": "string",
            "km": "string",
            "location": "string",
            "municipality": "string",
            "passageDate": "2021-06-16T06:23:56.493Z",
            "passageDateUtc": "2021-06-16T06:23:56.493Z",
            "road": "string",
            "tollSectionId": 0,
            "tollSectionName": "string",
            "typeBG": "string",
            "typeEN": "string"
          }
        ],
        "hasExpired": false,
        "licensePlate": "string",
        "nationality": "string",
        "owners": [
          {
            "vehicleOwnerId": "string"
          }
        ],
        "paymentDocumentNumber": "string"
      }
    ],
    "totalElements": 0,
    "totalPages": 0
  }

export const productTypesAnnouncements = [
    { id: 'TOLL', name: 'TOLL' },
    { id: 'TOP_UP', name: 'TOP_UP' },
    { id: 'INITIAL_PAYMENT', name: 'INITIAL_PAYMENT' },
    { id: 'ROUTE_PASS', name: 'ROUTE_PASS' },
    { id: 'SERVICE_FEE', name: 'SERVICE_FEE' },
    { id: 'DEPOSIT', name: 'DEPOSIT' },
    { id: 'DEPOSIT_RELEASE', name: 'DEPOSIT_RELEASE' },
    { id: 'TOLL_ADJUSTMENT_CREDIT', name: 'TOLL_ADJUSTMENT_CREDIT' },
    { id: 'TOLL_ADJUSTMENT_DEBIT', name: 'TOLL_ADJUSTMENT_DEBIT' },
    { id: 'E_VIGNETTE', name: 'E_VIGNETTE' },
    { id: 'COMPENSATORY_FEE', name: 'COMPENSATORY_FEE' },
    { id: 'MAXIMUM_TOLL', name: 'MAXIMUM_TOLL' },
    { id: 'SERVICE_FEE_ADJUSTMENT_DEBIT', name: 'SERVICE_FEE_ADJUSTMENT_DEBIT' },
    { id: 'SERVICE_FEE_ADJUSTMENT_CREDIT', name: 'SERVICE_FEE_ADJUSTMENT_CREDIT' },
    { id: 'DEPOSIT_ADJUSTMENT_DEBIT', name: 'DEPOSIT_ADJUSTMENT_DEBIT' },
    { id: 'DEPOSIT_ADJUSTMENT_CREDIT', name: 'DEPOSIT_ADJUSTMENT_CREDIT' },
    { id: 'DEPOSIT_RELEASE_ADJUSTMENT_DEBIT', name: 'DEPOSIT_RELEASE_ADJUSTMENT_DEBIT' },
    { id: 'DEPOSIT_RELEASE_ADJUSTMENT_CREDIT', name: 'DEPOSIT_RELEASE_ADJUSTMENT_CREDIT' },
    { id: 'EQUIPMENT_CHARGE_PENALTY', name: 'EQUIPMENT_CHARGE_PENALTY' },
    { id: 'EQUIPMENT_CHARGE_ADJUSTMENT_CREDIT', name: 'EQUIPMENT_CHARGE_ADJUSTMENT_CREDIT' },
    { id: 'EQUIPMENT_CHARGE_ADJUSTMENT_DEBIT', name: 'EQUIPMENT_CHARGE_ADJUSTMENT_DEBIT' },
    { id: 'NOT_TOLL', name: 'NOT_TOLL' },
    { id: 'NOT_VIGNETTE', name: 'NOT_VIGNETTE' }
];

export const clientTypes = [
    { "id": 1, "code": 1, "description": 'Индивидуален' },
    { "id": 2, "code": 2, "description": 'Бизнес' }
];

export const c5Languages = [
    { "id": 1, "description": "English - United States", "cultureName": "US", "default": true, "translations": [] },
    { "id": 2, "description": "Spanish - Latin America", "cultureName": "AR", "default": false, "translations": [] },
    { "id": 3, "description": "Bulgarian - Bulgaria", "cultureName": "BG", "default": false, "translations": [] }]

export const preferredLanguages = [
    { "id": "en", "name": "English - United States"},
    { "id": "bg", "name": "Bulgarian - Bulgaria"}]

export const emissionClasses = [
    { "id": 0, "description": "Euro 0" },
    { "id": 1, "description": "Euro 1" },
    { "id": 2, "description": "Euro 2" },
    { "id": 3, "description": "Euro 3" },
    { "id": 4, "description": "Euro 4" },
    { "id": 5, "description": "Euro 5" },
    { "id": 6, "description": "Euro 6" },
    { "id": 15, "description": "EEV" }];

export const vehicleClasses = [
    { "id": 3, "description": "Автобус (UNECE клас М2, М3)" },
    { "id": 4, "description": "Превозно средство между 3.5 и 12 т (UNECE клас N2 )" },
    { "id": 5, "description": "Превозно средство над 12 т (UNECE клас N3 )" },
    { "id": 7, "description": "Други превозни средства над 3.5 т невключени в останалите групи" }];

export const DEFAULT_MAP_ZOOM = 8;
export const MAP_CENTER = { lat: 42.8489128, lng: 25.2552363 };
export const ROUTE_COLOR = '#81f081';
export const ROUTE_BORDER_COLOR = '#30ba30';
export const TOLL_CHARGE_COLOR = '#ff7373'
export const SELECTED_TOLL_CHARGE_COLOR = '#ed2e0c'
export const ROUTE_WEIGHT = 5;
export const DATE_FORMAT = 'yyyy-MM-dd';

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const initialHtml = `<br/><br/>Поздрави, <br/>Екипът на <a href="www.digitoll.bg">www.digitoll.bg</a> <div><p><img src="https://www.digitoll.bg/Logo.jpg" style="width: 200px" alt=""/></p></div>`


export const reportTypesConstants = {
    elements: [
        {
            id: 1,
            type: "DAILY",
            name: "Daily"
        },
        {
            id: 2,
            type: "DAILY_COMPENSATORY_FEE",
            name: "Daily compensatory fee"
        },
        {
            id: 3,
            type: "DAILY_COMMISSIONER",
            name: "Daily commissioner"
        },
        {
            id: 4,
            type: "DAILY_PARTNER_SUMMARY",
            name: "Daily partner summary"
        },
        {
            id: 5,
            type: "DAILY_PRODUCT_SUMMARY",
            name: "Daily product summary"
        },
        {
            id: 6,
            type: "DAILY_ROUTE_PASS",
            name: "Daily route pass"
        },
        {
            id: 7,
            type: "DAILY_ROUTE_PASS_PARTNER_SUMMARY",
            name: "Daily route pass partner summary"
        },
        {
            id: 8,
            type: "DAILY_COMPENSATORY_FEE_PARTNER_SUMMARY",
            name: "Daily compensatory fee partner summary"
        },
        {
            id: 10,
            type: "FIVE_DAY_SUMMARY_ROUTE_PASS",
            name: "Five day summary route pass"
        },
        {
            id: 11,
            type: "FIVE_DAY_SUMMARY_COMPENSATORY_FEE",
            name: "Five day summary compensatory fee"
        },
        {
            id: 12,
            type: "FIVE_DAY_SUMMARY",
            name: "Five day summary"
        },
        {
            id: 13,
            type: "WEEKLY_SUMMARY",
            name: "Weekly summary"
        },
        {
            id: 14,
            type: "WEEKLY_SUMMARY_ROUTE_PASS",
            name: "Weekly summary route pass"
        },
        {
            id: 15,
            type: "WEEKLY_SUMMARY_COMPENSATORY_FEE",
            name: "Weekly summary compensatory fee"
        },
        {
            id: 16,
            type: "MONTHLY_PARTNER_SUMMARY",
            name: "Monthly partner summary"
        },
        {
            id: 17,
            type: "MONTHLY_SUMMARY",
            name: "Monthly summary"
        },
        {
            id: 18,
            type: "MONTHLY_COMMISSIONER",
            name: "Monthly commissioner"
        },
        {
            id: 19,
            type: "MONTHLY_SUMMARY_ROUTE_PASS",
            name: "Monthly summary route pass"
        },
        {
            id: 20,
            type: "MONTHLY_SUMMARY_COMPENSATORY_FEE",
            name: "Monthly summary compensatory fee"
        },
        {
            id: 22,
            type: "TWICE_MONTHLY_ROUTE_PASS",
            name: "Twice monthly route pass"
        },
        {
            id: 23,
            type: "TWICE_MONTHLY_COMPENSATORY_FEE",
            name: "Twice monthly compensatory fee"
        },
        {
            id: 24,
            type: "TWICE_MONTHLY_PARTNER_SUMMARY",
            name: "Twice monthly partner summary"
        },
        {
            id: 25,
            type: "TWICE_MONTHLY_SUMMARY",
            name: "Twice monthly summary"
        },
        {
            id: 27,
            type: "TWICE_MONTHLY_COMMISSIONER",
            name: "Twice monthly commissioner"
        },
        {
            id: 28,
            type: "ALL_COMMISSIONS",
            name: "All commissions"
        },
        {
            id: 29,
            type: "DAILY_COMMISSIONERS_SUMMARY",
            name: "[Accounting] Daily commissioner summary"
        },
        {
            id: 30,
            type: "TWICE_MONTHLY_COMMISSIONERS_SUMMARY",
            name: "[Accounting] Twice monthly commissioner summary"
        },
        {
            id: 31,
            type: "MONTHLY_COMMISSIONERS_SUMMARY",
            name: "[Accounting] Monthly commissioner summary"
        },
    ]
};

export const reportTypesTollConstants = {
    elements: [
        {
            id: 7,
            type: "WEEKLY_PARTNER_KAPSCH_SUMMARY",
            name: "Weekly partner Kapsch summary"
        },
        {
            id: 8,
            type: "MONTHLY_PARTNER_KAPSCH_SUMMARY",
            name: "Monthly partner Kapsch summary"
        },
        {
            id: 12,
            type: "DAILY_TOLLING",
            name: "Daily tolling"
        },
        {
            id: 13,
            type: "MONTHLY_CUSTOMER_TOLLING",
            name: "Monthly customer tolling"
        },
        {
            id: 14,
            type: "HALF_MONTH_TOLLING",
            name: "Half month tolling"
        },
        {
            id: 15,
            type: "DAILY_TOLLING_PARTNER_SUMMARY",
            name: "Daily tolling partner summary"
        },
        {
            id: 16,
            type: "DAILY_TOLLING_PARTNER_CUSTOMERS_SUMMARY",
            name: "Daily tollling partner customers summary"
        },
        {
            id: 18,
            type: "TWICE_MONTHLY_TOLLING",
            name: "Twice monthly tolling"
        },
        {
            id: 19,
            type: "TWICE_MONTHLY_TOLLING_COMMISSIONER",
            name: "Twice monthly tolling commissioner"
        },
        {
            id: 21,
            type: "MONTHLY_TOLLING_PARTNER_CUSTOMERS",
            name: "Monthly tolling partner customers"
        },
        {
            id: 22,
            type: "MONTHLY_TOLLING_COMMISSIONER",
            name: "Monthly tolling commissioner"
        },
        {
            id: 23,
            type: "ALL_COMMISSIONS",
            name: "All commissions"
        },
        {
            id: 24,
            type: "MONTHLY_TOLLING",
            name: "Monthly tolling"
        },
        {
            id: 25,
            type: "DAILY_TOLLING_COMMISSIONER",
            name: "Daily tolling commissioner"
        },
        {
            id: 26,
            type: "TWICE_MONTHLY_TOLLING_PARTNER",
            name: "Twice monthly tolling partner"
        },
        {
            id: 27,
            type: "FIVE_DAY_TOLLING_PARTNER",
            name: "Five day tolling partner"
        },
        {
            id: 28,
            type: "WEEKLY_TOLLING_PARTNER",
            name: "Weekly tolling partner"
        },
        {
            id: 29,
            type: "DAILY_PARTNER_KAPSCH_SUMMARY",
            name: "Daily partner Kapsch summary"
        },
        {
            id: 30,
            type: "TWICE_MONTHLY_PARTNER_KAPSCH_SUMMARY",
            name: "Twice monthly partner Kapsch summary"
        },
      ]
};

export const timeZoneSelect = [
    { id: 'Europe/Sofia', name: 'Europe/Sofia' },
    { id: 'UTC', name: 'UTC' }
];


export const serviceFeePeriod = [
    { id: 'NONE', name: 'NONE', label: 'APP.ERP.SERVICE_FEES.PERIOD_NONE' },
    { id: 'MONTH', name: 'MONTH', label: 'APP.ERP.SERVICE_FEES.PERIOD_MONTH' }
];

export const serviceFeeType = [
    { id: 'FIX', name: 'FIX', label: 'APP.ERP.SERVICE_FEES.TYPE_FIX'},
    { id: 'ACCOUNT_UNIT', name: 'ACCOUNT_UNIT', label: 'APP.ERP.SERVICE_FEES.TYPE_ACCOUNT_UNIT' }
];

export const serviceFeeInvoiceBasis = [
    { id: 'MATERIAL', name: 'NONE', label: 'APP.ERP.SERVICE_FEES.INVOICE_BASIS_MATERIAL' },
    { id: 'SERVICE', name: 'MONTH', label: 'APP.ERP.SERVICE_FEES.INVOICE_BASIS_SERVICE' }
];

export const newFinancialDocumentTypes = [
    {id: 'DEBIT_NOTE', name: 'DEBIT_NOTE', label: 'APP.ERP.SERVICE_FEES.TYPE_DEBIT_NOTE'},
    {id: 'CREDIT_NOTE', name: 'CREDIT_NOTE', label: 'APP.ERP.SERVICE_FEES.TYPE_CREDIT_NOTE'},
];

export const invoiceType = [
    {id: 'INVOICE', name: 'INVOICE', label: 'APP.ERP.SERVICE_FEES.TYPE_INVOICE'},
    {id: 'DEBIT_NOTE', name: 'DEBIT_NOTE', label: 'APP.ERP.SERVICE_FEES.TYPE_DEBIT_NOTE'},
    {id: 'CREDIT_NOTE', name: 'CREDIT_NOTE', label: 'APP.ERP.SERVICE_FEES.TYPE_CREDIT_NOTE'},
];

export const transactionStatus = {
    BILLED: 'BILLED',
    UNBILLED: 'UNBILLED',
    EXPECTED_BILLED_VALUE: 'Billed'
};

export const docsSendingTollType = [
    {id: 'BG_TOLL', name: 'APP.ERP.MSTS_BG_TOLL'},
    {id: 'EURO_TOLL', name: 'APP.ERP.MSTS_EURO_TOLL'}
];

export const delictNotificationType = [
    {id: 'PARTNER_NOTIFICATION', label: 'APP.COMMON.COMMON.C10A_NOTIFICATIONS_PARTNER'},
    {id: 'CUSTOMER_NOTIFICATION', label: 'APP.COMMON.COMMON.C10A_NOTIFICATIONS_CLIENT'},
    {id: 'PARTNER_NOTIFICATION,CUSTOMER_NOTIFICATION', label: 'APP.COMMON.COMMON.C10A_NOTIFICATIONS_PARTNER_AND_CLIENT'},
];

export const delictTypes = {
    "Макс тол такса":"APP.WEBSITE.DELICTS.TYPE_MAX_TOLL",
    "OTHER":"APP.WEBSITE.DELICTS.TYPE_COMPENSATORY_FEE",
}

export const bankType = [
    {id: 'VPOS', name: 'VPOS', label: 'APP.ERP.PARTNER.INVOICING_VPOS'},
    {id: 'BANK', name: 'BANK', label: 'APP.COMMON.COMMON.PAYMENT_TYPE_BANK'},
    {id: 'SYSTEM', name: 'SYSTEM', label: 'APP.COMMON.COMMON.SYSTEM_PAYMENTS'}
];

export const obligationType = [
    {id: 'TOLL', name: 'TOLL', label: 'APP.ERP.CONTRACT.TOLL'},
    {id: 'DIGITOLL_SERVICE_FEE', name: 'DIGITOLL_SERVICE_FEE', label: 'APP.COMMON.COMMON.SERVICE_FEE'}
];

export const vPosTerminals = {
    paymentServiceTollRoadly: 'APP.COMMON.COMMON.ROADLY_VPOS',
    paymentServiceTollDigiToll: 'APP.COMMON.COMMON.DIGITOLL_VPOS'
}

const DIGITOLL_BANK_ACCOUNT_IBAN = 'BG79SOMB91301000001617'
const ROADLY_UNICREDIT_BANK_ACCOUNT_IBAN = 'BG24UNCR70001525565974'

export const recipientIbanList = [DIGITOLL_BANK_ACCOUNT_IBAN, ROADLY_UNICREDIT_BANK_ACCOUNT_IBAN];

export const borikaActionMessages = {
    '0': 'APP.BORIKA.ACTION_MESSAGES.0',
    '1': 'APP.BORIKA.ACTION_MESSAGES.1',
    '2': 'APP.BORIKA.ACTION_MESSAGES.2',
    '3': 'APP.BORIKA.ACTION_MESSAGES.3',
    '7': 'APP.BORIKA.ACTION_MESSAGES.7',
    '21': 'APP.BORIKA.ACTION_MESSAGES.21'
};

export const borikaResultCodeMessages = {
    '-1': 'APP.BORIKA.ERRORS.-1',
    '-2': 'APP.BORIKA.ERRORS.-2',
    '-3': 'APP.BORIKA.ERRORS.-3',
    '-4': 'APP.BORIKA.ERRORS.-4',
    '-5': 'APP.BORIKA.ERRORS.-5',
    '-6': 'APP.BORIKA.ERRORS.-6',
    '-7': 'APP.BORIKA.ERRORS.-7',
    '-10': 'APP.BORIKA.ERRORS.-10',
    '-11': 'APP.BORIKA.ERRORS.-11',
    '-12': 'APP.BORIKA.ERRORS.-12',
    '-13': 'APP.BORIKA.ERRORS.-13',
    '-15': 'APP.BORIKA.ERRORS.-15',
    '-16': 'APP.BORIKA.ERRORS.-16',
    '-17': 'APP.BORIKA.ERRORS.-17',
    '-19': 'APP.BORIKA.ERRORS.-19',
    '-20': 'APP.BORIKA.ERRORS.-20',
    '-21': 'APP.BORIKA.ERRORS.-21',
    '-22': 'APP.BORIKA.ERRORS.-22',
    '-23': 'APP.BORIKA.ERRORS.-23',
    '-24': 'APP.BORIKA.ERRORS.-24',
    '-25': 'APP.BORIKA.ERRORS.-25',
    '-26': 'APP.BORIKA.ERRORS.-26',
    '-27': 'APP.BORIKA.ERRORS.-27',
    '-28': 'APP.BORIKA.ERRORS.-28',
    '-29': 'APP.BORIKA.ERRORS.-29',
    '-30': 'APP.BORIKA.ERRORS.-30',
    '-31': 'APP.BORIKA.ERRORS.-31',
    '-32': 'APP.BORIKA.ERRORS.-32',
    '-33': 'APP.BORIKA.ERRORS.-33',
    '-40': 'APP.BORIKA.ERRORS.-40',
    '00': 'APP.BORIKA.ERRORS.00',
    '01': 'APP.BORIKA.ERRORS.01',
    '04': 'APP.BORIKA.ERRORS.04',
    '05': 'APP.BORIKA.ERRORS.05',
    '06': 'APP.BORIKA.ERRORS.06',
    '12': 'APP.BORIKA.ERRORS.12',
    '13': 'APP.BORIKA.ERRORS.13',
    '14': 'APP.BORIKA.ERRORS.14',
    '15': 'APP.BORIKA.ERRORS.15',
    '17': 'APP.BORIKA.ERRORS.17',
    '30': 'APP.BORIKA.ERRORS.30',
    '35': 'APP.BORIKA.ERRORS.35',
    '36': 'APP.BORIKA.ERRORS.36',
    '37': 'APP.BORIKA.ERRORS.37',
    '38': 'APP.BORIKA.ERRORS.38',
    '39': 'APP.BORIKA.ERRORS.39',
    '40': 'APP.BORIKA.ERRORS.40',
    '41': 'APP.BORIKA.ERRORS.41',
    '42': 'APP.BORIKA.ERRORS.42',
    '43': 'APP.BORIKA.ERRORS.43',
    '54': 'APP.BORIKA.ERRORS.54',
    '55': 'APP.BORIKA.ERRORS.55',
    '56': 'APP.BORIKA.ERRORS.56',
    '57': 'APP.BORIKA.ERRORS.57',
    '58': 'APP.BORIKA.ERRORS.58',
    '59': 'APP.BORIKA.ERRORS.59',
    '85': 'APP.BORIKA.ERRORS.85',
    '88': 'APP.BORIKA.ERRORS.88',
    '89': 'APP.BORIKA.ERRORS.89',
    '91': 'APP.BORIKA.ERRORS.91',
    '95': 'APP.BORIKA.ERRORS.95',
    '96': 'APP.BORIKA.ERRORS.96'
};